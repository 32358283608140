<template>
	<div>
		<h1>404，你的页面走丢了</h1>
        <router-link to="/">回到首页</router-link>
	</div>
</template>

<script>
export default {
	name: '',
}
</script>

<style scoped></style>
